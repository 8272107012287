.hardware-table {
    background-image: url("../../../assets/images/testiGlow.webp");
    background-position: center;
    background-size: 100% 100%;
    color: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    background-image: url("../../../assets/images/trGlow.webp");
    background-position: center;
    background-size: 100% 100%;

    h2 {
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: transparent;

        // opa
        // Remove borders from the table
        border: none;

        // Responsive styles for table
        th,
        td {
            // Remove the border and set a light gray color for rows
            border: none;
            padding: 15px; // Increased padding for row height
            text-align: left;
            font-size: 16px; // Base font size for desktop
            background-color:transparent; // Slightly transparent white for row background
        }

        th {
            // background-color: #444;
            border-bottom: 1px solid white;
        }

        tr {
            border-bottom: 1px solid #444 !important;
        }

        // tr:nth-child(even) {
        //     background-color: rgba(255, 255, 255, 0.05); // Light gray for even rows
        // }

        // tr:hover {
        //     background-color: #333; // Darker background on hover
        // }
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
            background-color: #444;
            color: #fff;
            border: none;
            padding: 10px 20px;
            margin: 0 10px;
            border-radius: 4px;
            cursor: pointer;

            &:disabled {
                background-color: #666;
                cursor: not-allowed;
            }

            &:hover:not(:disabled) {
                background-color: #555;
            }
        }

        span {
            align-self: center;
            margin: 0 10px;
        }
    }

    // Media queries for responsiveness
    @media (max-width: 768px) {
        padding: 15px;
        margin: 10px;

        h2 {
            font-size: 32px;
        }

        table {
            font-size: 14px; // Smaller font size for table text
            overflow-x: scroll;

            th,
            td {
                padding: 10px; // Adjust padding for smaller screens
            }
        }
    }

    @media (max-width: 480px) {
        padding: 10px;
        margin: 5px;

        .d-none-mbl {
            display: none;
        }

        h2 {
            font-size: 32px;
        }

        table {
            font-size: 12px; // Smaller font size for very small screens

            th,
            td {
                padding: 8px; // Further reduce padding
            }
        }

        .pagination {
            // flex-direction: column; // Stack pagination buttons on small screens
            // column-gap: 30px;
            justify-content: center;
            padding: 0px 30px;

            column-gap: 20px;

            
        span {
            align-self: center;
            margin: 0 2px;
        }

            button {
                margin: 5px 0; // Margin adjustment for buttons
                // width: 80%; // Make buttons full width
                align-self: center;

            }
        }
    }

    .responsiveness {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; // Full width of the parent
    }

    .responsiveness iframe {
        width: 80%; // Default width for larger screens
        max-width: 800px; // Optional: max width to prevent it from getting too large
        height: 420px; // Keep the height as you specified
        margin: 0 auto; // Center the iframe
    }

    // Media query for smaller devices
    @media (max-width: 768px) {
        .responsiveness iframe {
            width: 95%; // Increase width on small devices
            height: 300px; // Optional: Adjust height if needed
        }
    }

}

// .img {
//     background-image: url("../../../assets/images/trGlow.webp");
//     background-position: center;
//     background-size: 100% 100%;
//     transform: scaleX(-1);
// }

.responsiveness {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; // Full width of the parent

    /* Flip the image horizontally */

}

.responsiveness::before {
    content: '';
    /* Necessary for pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../assets/images/trGlow.webp");
    background-position: center;
    background-size: cover;
    /* Cover the entire element */
    transform: scaleX(-1);
    /* Flip the image horizontally */
    z-index: -1;
    /* Send it behind the content */
    opacity: 0.7;
    /* Optional: Adjust opacity if needed */
}

.responsiveness {
    h2 {
        font-weight: bold;
        margin: 10px 0px;
    }
}

.responsiveness iframe {
    width: 95%; // Default width for larger screens
    max-width: 1200px; // Optional: max width to prevent it from getting too large
    height: 400px; // Keep the height as you specified
    margin: 0 auto; // Center the iframe



}

// Media query for smaller devices
@media (max-width: 768px) {
    .responsiveness iframe {
        width: 95%; // Increase width on small devices
        height: 430px; // Optional: Adjust height if needed
    }

    .responsiveness h2 {
        font-size: 30px;
    }
}
.admin-page {
    display: flex;

    .sidebar {
        width: 250px;
        background-color: #333;
        color: #fff;
        height: 100vh;
        padding: 20px;

        ul {
            list-style-type: none;
            padding: 0;

            li {
                padding: 10px;
                cursor: pointer;

                &:hover,
                &.active {
                    background-color: #555;
                }
            }
        }
    }

    .content {
        flex: 1;
        padding: 20px;
    }

}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height to center spinner */
}
.form-input {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  color: var(--dark-black);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 10px 10px 15px;
  height: 65px;
  max-width: 100%;
  width: 100% !important;
  &::placeholder {
    color: var(--med-gray);
  }
}

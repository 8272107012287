@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');



.service-container {
  padding: 2em 0 1em 0;
  position: relative;
  width: 100%;
  // text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  background-image: url("../../../assets/images/trGlow.webp");
  background-position: center;
  background-size: 100% 100%;
  // background: linear-gradient(to right, #533b80, black);
  // background: radial-gradient(#7b1788, #000000);

  // .line {
  //   width: 100px;
  //   height: 4px;
  //   margin-top: 20px;
  //   margin-left: 10px;
  //   margin-bottom: 10px;
  //   // margin: 10px 40px;
  //   background-color: #4C097D;
  //   border-radius: 10px;
  // }

  .container {
    max-width: 1300px;
    margin: 0 auto;

    h2 {
      // font-family: "Gowun Batang", serif !important;
      font-weight: bold;
      text-align: center;

      // text-shadow: 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83,
      //   4px 4px 4px #4C097D;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      margin: 0 -15px;
    }

    .service-card {
      width: calc(33.333% - 30px);
      background: #1c1c1c;
      /* Fallback color */
      padding: 30px;
      margin: 15px;
      // text-align: center;
      border-radius: 5px;
      position: relative;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      color: white;
      height: 300px;
      /* Default text color for better contrast */

      /* Pseudo-element for overlay */
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px;
        /* Match the card's border-radius */
        background-color: rgba(0, 0, 0, 0.6);
        /* Adjust opacity for a darker overlay */
        z-index: 1;

        /* Place overlay above the image but below the text */
      }

      h3 {
        text-align: start;
        margin-top: 100px !important;
        color: white !important;
        z-index: 2;
        position: relative;

      }

      button {
        position: relative;
        /* Position text above the overlay */
        z-index: 2;
        text-align: start;
        padding: 4px 8px;
        background-color: transparent;
        border: 2px solid white;
        color: white;
        border-radius: 20px;
        /* Ensure text is above the overlay */
        /* Ensure text is above the overlay */

        &:hover {
          background-color: #4C097D;
          /* Change background color on hover */
          color: white;
          /* Change text color on hover */
          border: 2px solid #4C097D;
        }
      }



      &.active {
        background-image: url(../../../assets/crypto3.jpg);
        /* Replace with your image path */
        background-size: cover;
        background-position: center;
      }

      &:nth-child(2) {
        background-image: url(../../../assets/containerImgday10.jpeg);
        /* Replace with your image path */
        background-size: cover;
        background-position: center;
      }

      &:nth-child(3) {
        background-image: url(../../../assets/crypto7.jpg);
        /* Replace with your image path */
        background-size: cover;
        background-position: center;
      }

      &:hover {
        background-color: rgba(75, 46, 131, 0.8);
        /* Optional: change to a semi-transparent color on hover */
        transform: translateY(-5px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);

        .icon {
          color: white;
        }
      }

      h3 {
        margin-bottom: 15px;
        font-size: 30px;
        padding: 0;
        line-height: 1.7;
      }

      p {
        color: white;
        /* Ensure text is white for better contrast */
        font-size: 14px;
      }

      .icon {
        font-size: 50px;
        color: #4C097D;
        margin-bottom: 20px;
      }
    }


  }
}

@media only screen and (max-width: 900px) {
  .service-container {
    .container {
      .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 20px !important;
        margin: 0px;
      }

      .service-card {
        width: 100%;
        max-width: 400px;
        margin: 0px;

        h3 {}

        h2 {
          text-align: start;
        }

        p {
          font-size: 14px;
        }

        .icon {
          font-size: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .service-container {
    padding: 0.5em 0 1em 0;

    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 10px;
      // text-align: start;
    }

    .container {
      .row {
        grid-template-columns: repeat(1, 1fr);
      }

      .service-card {
        max-width: 100%;
      }
    }
  }
}
.btn-primary {
  width: fit-content;
  display: inline-flex;
  height: 45px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 72px;
  background: var(--primary-dark, #4b2e83);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  //  text-transform: uppercase;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.exchange-rates-container {
  width: 100%;
  height: 60px !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee-item {
  font-size: 16px;
}

.carousel-wrapper {
  width: 100%;
  margin: 0 auto;
}

.custom-carousel .carousel .slide {
  height: 400px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.custom-carousel .carousel .slide img {
  height: 400px;
  object-fit: cover;
  object-position: center;
}

.carousel-text {
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
  z-index: 2;
  max-width: 60%;
  text-align: start;

  .contactBtn {
    position: relative;
    /* Position text above the overlay */
    z-index: 2;
    text-align: start;
    padding: 5px 12px;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 20px;

    &:hover {
      background: white;
      color: #4C097D;
      font-weight: bold;
    }
  }
}

.carousel-text h2 {
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-family: "Roboto Slab", serif;
  font-weight: bold;
}

.carousel-text p {
  font-size: 20px;
  line-height: 1.5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
  text-align: start;
  // font-family: "Gowun Batang", serif !important;
  font-weight: bold;
}

/* Responsive adjustments for tablet and mobile */
@media screen and (max-width: 768px) {
  .carousel-text h2 {
    font-size: 30px;
  }

  .carousel-text p {
    font-size: 16px;
  }

  .custom-carousel .carousel .slide {
    height: 300px;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .carousel-text {
    top: 40%;
    left: 14%;
    max-width: 80%;
    text-align: center;
    /* Center text horizontally */
  }



  .custom-carousel .carousel .slide {
    height: 200px;
    position: relative;
  }

  .carousel-text h2 {
    font-size: 25px;
    text-align: start;
  }

  .carousel-text p {
    font-size: 12px;
    text-align: center;
  }

  .carousel .contactBtn {}
}

.language-switch-container {
  display: none;
}

.language-dropdown {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  max-height: 0; // Initial height when closed
}

.language-dropdown.open {
  max-height: 200px; // Adjust height to fit content
}

/* Hide dropdown on larger screens */
.language-switch-container {
  display: none;
}

/* Show dropdown only on mobile view */
@media (max-width: 767px) {
  .language-switch-container {
    display: block;
  }
}
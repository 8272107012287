.product-page {
    padding: 20px;

    .add-product-btn {
        padding: 10px 20px;
        margin-bottom: 20px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: #555;
        }
    }

    .product-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;

        th,
        td {
            padding: 12px;
            border: 1px solid #ddd;
            text-align: left;
        }

        th {
            background-color: #f4f4f4;
            color: black;

        }

        img {
            max-width: 100%;
            height: auto;
            border-radius: 4px;
        }

        button {
            padding: 3px 10px;
            margin-bottom: 20px;
            background-color: red;
            color: white;
            border: none;
            border-radius: 4px;
        }
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);

        .modal-content {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            max-width: 500px;
            width: 100%;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            text-align: left;
            position: relative;

            h2 {
                color: black;
            }

            .close {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 24px;
                cursor: pointer;
                color: #333;
            }

            .product-form {
                .form-group {
                    margin-bottom: 15px;

                    label {
                        display: block;
                        margin-bottom: 5px;
                        color: black;
                    }

                    input,
                    select {
                        width: 100%;
                        padding: 10px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                    }
                }

                button {
                    padding: 10px 20px;
                    background-color: #333;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;

                    &:hover {
                        background-color: #555;
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        padding: 30px;

        button {
            padding: 3px 16px;
            margin: 0px 10px;
            border-radius: 3px;
        }
    }
}

.label.ant-form-item-required {
    color: white !important;
}

.form-label {
    color: white !important;
}
.map-container {
    padding: 20px;
    margin-top: 4em;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .location-dropdown {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .map-wrapper {
        height: 400px;
        width: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
    }
}
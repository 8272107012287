@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

.why-choose-container {
  padding: 50px 20px 30px 20px;


  h1 {
    // font-family: "Gowun Batang", serif !important;
    // font-weight: bold;
  }

  .listItem {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;

    .order {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
      padding: 30px;
      gap: 15px;
      border-radius: 50px;
      // background: #4b2e83;
      background: #1c1c1c;
      box-shadow: 0px 0px 12px 0px #4C097D;

      h2 {
        color: #fff;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .why-choose-container {
    h1 {
      font-size: 32px;
      // font-family: "Gowun Batang", serif !important;
      // font-weight: bold;
    }


    .listItem {
      grid-template-columns: repeat(1, 1fr);
      gap: 36px;
      width: 100%;

      .order {
        padding: 30px;
        gap: 15px;

        h2 {
          font-size: 24px;

        }
      }
    }
  }
}
.requestDemo-main-container {
  width: 100%;
  margin: auto;
  background-image: url("../../../assets/images/trGlow.webp");
  padding: 40px 150px;
  margin-top: 100px;
  background-position: center;
  background-size: 100% 100%;

  // background-image: url("/assets/contactus.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // position: relative;
  // /* Required for the overlay */

  /* Overlay */
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: rgba(0, 0, 0, 0.5);
  //   /* Black overlay with 50% opacity */
  //   z-index: 0;
  //   /* Keep the overlay behind the content */
  // }

  h2 {
    font-weight: bold;
    // text-shadow: 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83;
  }

  /* Ensure content is on top of the overlay */
  .content {
    position: relative;
    z-index: 1;
  }

  .requestDemo-twoInputbox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    input {
      width: 100%;
      border-radius: 10px;
      border: 1px solid var(--Gray-3, #828282);
      background: #f9f9f9;
      padding: 10px 20px;
    }
  }

  input,
  textarea {
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--Gray-3, #828282);
    background: #f9f9f9;
    margin-top: 20px;
    padding: 10px 20px;
  }

  .send-request-btn {
    width: 100%;
    border-radius: 24px;
    background: var(--primary-light, #4C097D);
    padding: 12px 20px;
    margin-top: 20px;
    color: var(--white, #fff);
    font-size: 18px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 700px) {
  .requestDemo-main-container {
    width: 80%;
    padding: 30px 20px;

    .requestDemo-twoInputbox {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}
/* TrackOrders.css */
.track-orders-container {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;


    /* Optional: Add a background color */
    .coming-soon-text {
        font-size: 24px;


    }

}
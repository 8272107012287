.product-listing-container {
  padding-top: 50px;
  padding-bottom: 30px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: white;
  }

  p {
    text-align: center;
    color: #777;
    margin-bottom: 20px;
  }

  .product-listing-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .search-bar {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      input {
        width: 80%;
        max-width: 400px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 1rem;

        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }
    }

    .side-filters {
      flex: 1 0 18%;
      background-color: #333;
      padding: 20px;
      border-radius: 8px;
      margin-right: 20px;


      h3 {
        margin-bottom: 15px;
        font-size: 1.2rem;
        color: white;
      }

      label {
        display: block;
        margin-bottom: 10px;
        font-size: 1rem;
        color: white;

        input[type="checkbox"],
        input[type="number"] {
          margin-right: 5px;
        }

        input[type="number"] {
          width: 100%;
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 5px;
        }
      }
    }

    .product-grid {
      // padding: 30;
      flex: 1 0 70%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      // gap: 20px;/
      row-gap: 30px;
      column-gap: 30px;

      .truncated-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .product-card {
        // background-color: #333;
        border: 1px solid #333;
        padding: 15px;
        border-radius: 8px;
        text-align: center;
        transition: transform 0.2s ease-in-out;
        color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        height: 370px;
        width: 300px;
        overflow: hidden;

        &:hover {
          transform: translateY(-5px);
        }

        p {
          line-height: 30px !important;
        }

        h3 {
          line-height: 30px !important;
        }

        img {
          max-width: 100%;
          // height: auto;
          height: 208px;
          margin-bottom: 10px;
          border-radius: 5px;
        }

        .product-name {
          display: flex;
          justify-content: space-between;

          .power {
            color: #a890d3;
          }
        }

        .btn {
          background: transparent;
          color: white;
          border: 1px solid white;
          border-radius: 6px;
          padding: 6px 6px;
          font-size: 14px;
          margin-top: 5px;
          width: 100%;
          transition: background-color 0.3s ease;
          /* Add transition for smooth hover effect */

          &:hover {
            background-color: #4C097D;
            font-weight: bold;
            // border: 1px soli #4C097D;
            border: none;
            /* Change background color on hover */
          }
        }

        h3 {
          font-size: 1rem;
          color: #fff;
          margin-bottom: 5px;
          text-align: start;
        }

        p {
          font-size: 1rem;
          color: #ddd;
          margin-bottom: 5px;
          text-align: start;
        }

        p:last-child {
          font-size: 0.9rem;
          color: #28a745; // In-stock color
        }

        .out-of-stock {
          color: #dc3545 !important; // Out-of-stock color
        }
      }
    }
  }

  /* Media Queries */
  @media (max-width: 768px) {
    .search-bar input {
      width: 95%;
    }

    .side-filters {
      display: none;
      /* Hide the sidebar on small screens */
    }

    .product-listing-content {
      flex-direction: column;
      align-items: center;
      /* Center content on mobile */
    }

    .product-grid {
      justify-content: center;
      /* Center the grid items */
      width: 100%;
      /* Ensure the grid takes the full width */
      display: flex;
      /* Change display to flex for better control */
      flex-direction: column;
      /* Stack items vertically */
      padding-top: 20px;

      .product-card {
        width: 100%;
        /* Ensure cards take full width */
        max-width: 300px;
        /* Limit max width */
        margin: 0 auto;
        /* Center the card */
      }
    }
  }

  @media (max-width: 576px) {
    .product-listing-container {
      padding: 10px;
    }

    .search-bar input {
      width: 100%;
    }

    .product-grid {
      display: flex;
      /* Keep display flex */
      flex-direction: column;
      /* Stack items vertically */
      padding-top: 20px;
      row-gap: 10px;
      /* Space between items */

      .product-card {
        width: 100%;
        /* Ensure cards take full width */
        max-width: 300px;
        /* Limit max width */
        margin: 0 auto;
        /* Center the card */
      }
    }
  }
}
.about-us-container {

    position: relative;
    width: 100%;
    overflow: hidden;
    background-image: url("../../assets/images/trGlow.webp");
    background-position: center;
    background-size: 100% 100%;
    // padding: 0 20px;
    /* Padding for responsiveness */
}

/* Styling for the background image */
// .background-image {
//     position: relative;
//     background-image: url('./assets/aboutus.jpg');
//     /* Path to your background image */
//     background-size: cover;
//     background-position: center;
//     background-repeat: no-repeat;
//     height: 200px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

/* Black overlay with reduced opacity */
.background-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(2, 2, 2, 0.5);
    /* Black background with 50% opacity */
    z-index: 1;
    /* Place overlay behind text */
}

/* Overlay text styling */
.text-overlay {
    position: relative;
    color: white;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    z-index: 2;
    /* Place text above the overlay */
    text-transform: uppercase;
    word-spacing: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Styling for the heading */
.text-overlay h4 {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
}

.mission-bg {
    position: relative;
    background-image: url("../../assets/male-hand-with-golden-bitcoin-blue-background_155003-5308.jpg");
    /* Path to your background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;



    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Black color with 50% opacity */
        z-index: 0;

        /* Ensure the overlay is behind the content */
    }

    /* Styling for the mission statement card */
    .missionStatement {
        position: relative;
        z-index: 1;
        // margin-top: 1rem;
        padding: 20px;
        // background-color: black;
        border: 1px solid white;
        /* Light background color for the card */
        border-radius: 12px;
        /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for depth */
        max-width: 800px;
        /* Max width for responsiveness */
        margin-left: auto;
        margin-right: auto;

        /* Center the card horizontally */
    }

    .missionStatement h3 {
        margin-top: 0;
        /* Dark color for the heading */
        font-size: 1.8rem;
        /* Adjust font size as needed */
    }

    .missionStatement p {
        /* Gray color for the text */
        line-height: 1.6;
        /* Increase line-height for readability */
        font-size: 1rem;
        /* Adjust font size as needed */
    }
}

$primary-color: #4C097D;
$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(0, 0, 0, 0.2);

/* Team section */
.team-section {
    margin-top: 6rem;
    text-align: center;
}

.team-section h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    color: white;
}

.team-members {
    padding: 0px 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
}

.small-height {
    // height: 500px !important; // Adjust this value to the desired height
    object-fit: cover !important; // Ensures the image maintains its aspect ratio
    // width: ;
}

.team-card {
    position: relative;
    /* Position relative to allow absolute positioning for overlay */
    background-color: #1c1c1c;
    border-radius: 12px;
    overflow: hidden;
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 8);
    }

    .team-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(76, 9, 125, 0.9);
        /* Adjusted to 0.8 opacity */
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 30px;
        font-size: 14px;
        text-align: justify;
        gap: 15px;
        opacity: 0;
        /* This opacity will control the overall overlay visibility */
        transition: opacity 0.3s ease;
        z-index: 10;
        /* Make sure it appears above the content */
    }


    &:hover .team-overlay {
        opacity: 1;
        /* Show overlay on hover */
    }

    .team-info {
        padding: 10px;
    }

    .team-name {
        margin: 10px 0 5px;
        font-size: 1.2rem;
        font-weight: bold;
        color: white;
    }

    .team-role {
        margin: 0;
        font-size: 0.9rem;
        color: white;
    }

    .team-image {
        width: 100%;
        height: 320px;
        object-fit: cover;
    }

    .team-overlay a {
        color: #4C097D;
        /* Primary color for the social links */
        font-size: 1.5rem;
        transition: color 0.3s ease;

        &:hover {
            color: #fff;
            /* White color on hover */
        }
    }
}

@media (max-width: 1200px) {
    .team-members {
        padding: 0 2rem; // Reduce padding on smaller screens
        gap: 40px; // Decrease gap between team cards
    }

    // .team-card {
    //     width: 250px; // Reduce card width
    //     height: 350px; // Reduce card height
    // }

    .missionStatement {
        max-width: 90%; // Allow more width on smaller screens
        padding: 15px; // Adjust padding
    }

    .text-overlay h4 {
        font-size: 1.5rem; // Reduce heading size
    }
}

@media (max-width: 768px) {
    .about-us-container {
        padding: 0 10px; // Add some padding for smaller screens
    }

    .mission-bg {
        height: 400px; // Decrease height for mobile
    }

    .team-members {
        flex-direction: column; // Stack team cards vertically
        align-items: center; // Center align cards
    }

    // .team-card {
    //     width: 100%; // Full width for cards
    //     margin-bottom: 20px; // Add spacing between stacked cards
    // }

    .text-overlay h4 {
        font-size: 1.2rem; // Further reduce heading size
    }

    .missionStatement {
        max-width: 30%; // Allow ore width on smaller screens
        padding: 10px; // Adjust padding
    }

    .missionStatement h3 {
        font-size: 0.7rem; // Reduce mission heading size
        line-height: 35px;
    }

    .missionStatement p {
        // font-size: 0.9rem; // Adjust font size for text
        font-size: 10px !important;
    }
}

@media (max-width: 480px) {
    // .team-card {
    //     height: 300px; // Adjust height for smaller screens
    // }

    .team-name {
        font-size: 1rem; // Smaller name font size
    }

    .team-role {
        font-size: 0.8rem; // Smaller role font size
    }

    .team-overlay a {
        font-size: 1.2rem; // Smaller social link icons
    }
}
.blog-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
        padding: 12px;
        border: 1px solid #ddd;
        text-align: left;
    }

    th {
        background-color: #f4f4f4;
        color: black;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
    }

    button {
        padding: 5px 10px;
        margin-bottom: 10px;
        background-color: red;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
}

/* Add responsiveness */
@media (max-width: 768px) {
    .container {
        // padding: 20px
        /* Reduce padding on smaller screens */
    }

    .blog-table {

        th,
        td {
            padding: 8px;
            /* Adjust padding for smaller screens */
        }
    }

    /* Make the table scrollable horizontally */
    .blog-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    /* Ensure the form input fields stack properly */
    form {
        display: flex;
        flex-direction: column;

        .form-label {
            font-size: 14px;
            /* Smaller font for form labels */
        }

        input,
        textarea,
        button {
            margin-bottom: 10px;
            /* Add spacing between form elements */
        }
    }
}

@media (max-width: 480px) {

    /* Even tighter layout for very small screens */
    .blog-table th,
    .blog-table td {
        padding: 5px;
    }

    button {
        font-size: 12px;
        /* Smaller font for buttons */
        padding: 5px 8px;
    }
}
.navbar-container {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1000 !important;
  //border: 2px solid green;
  background-color: black;
  border-bottom: 1px solid #4C097D;
}

.navbarbtn-container {
  .name-container {
    display: flex;
    font-size: 15px;
    cursor: pointer;
    color: white !important;

    .icon {
      margin-right: 6px;

      margin-left: 3px;
    }

    p {
      font-size: 15px !important;
    }


  }

  button {
    // width: 175px;
    display: inline-flex;
    height: 45px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 72px;
    background: var(--primary-dark, #4b2e83);
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;

    @media only screen and (max-width: 1200px) {
      /* Adjust margins for screens smaller than 1200px */
      margin-top: 10px;
      /* Example adjustment */
      margin-left: 0;
      /* Reset left margin */
      padding: 20px;
      /* Adjust padding if necessary */
    }

    @media only screen and (max-width: 1000px) {
      /* Further adjustments for mobile screens */
      margin-top: 19px;
      /* Example adjustment */
      margin-left: -3px;
      /* Reset left margin */
      // padding: 15px;
      /* Further adjust padding */
      font-size: 14px;
      /* Adjust font size */
    }

    //  text-transform: uppercase;
  }
}

.navbar-inner-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  border-radius: 158px;
  color: white;
  // background: #fff;
  padding: 6px 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

  .logo-container {
    img {
      width: 218.876px;
      height: 70.77px;
    }
  }

  .menu-container {
    ul {
      display: flex;
      color: var(--white);

      li {
        position: relative;
        color: var(--white);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        margin: 0px 30px;
        transition: all 0.3s ease;

        &:hover {
          color: var(--primary-light-1, #4C097D);
        }

        // height: 30px;

        a {
          color: var(--white);

          .icon-rotate {
            transform: rotate(0deg);
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            color: #4C097D !important;
          }

          &:hover {
            .icon-rotate {
              color: #4C097D !important;
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out;
            }
          }

          &:hover:after {
            width: 100%;
            opacity: 0.9;
          }

          &.active {
            color: #4C097D !important;
            // &:after {
            //   width: 100%;
            //   content: "";
            //   display: block;
            //   height: 3px;
            //   left: 50%;
            //   transform: translate(-50%);
            //   bottom: -18%;
            //   position: absolute;
            //   border-radius: 5px;
            //   background: var(
            //     --3-color-grad,
            //     linear-gradient(90deg, #4b2e83 0%, #4C097D 52%, #4b2e83 100%)
            //   );
            //   opacity: 0.8;
            //   transition: width 0.8s ease 0s, opacity 0.3s ease 0s;
            // }
          }
        }
      }

      .liItem {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }

      .anticon svg {
        color: #4b2e83;
      }
    }
  }
}

.platform-main-outer {
  padding-top: 2px;
  max-width: 45%;
  position: absolute;
  left: 30%;

  .platform-hover-container {
    color: #fff;
    display: flex !important;
    justify-content: space-between;
    padding: 23px 12px;
    gap: 11px;
    margin-left: -100px;
    border-radius: 10px 0px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 99999;
    top: 40px;

    .platform-items {
      cursor: pointer;
      width: 219px;
      height: 45px;
      padding: 10px 15px 10px 10px;
      align-items: center;
      gap: 13px;
      border-radius: 12px;
      background: #fff;

      .left {
        gap: 13px;

        h6 {
          color: var(--primary-dark, #4b2e83);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
          /* 19.2px */
        }
      }

      .right-cursor {
        transform: translateX(0%);
        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        .right-cursor {
          transform: translateX(50%);
        }
      }
    }
  }
}

.languages-main-outer {
  padding-top: 3px;
  position: absolute;
  right: 8%;
  width: fit-content;

  // max-width: 100px;
  .platform-hover-container {
    color: #fff;
    display: flex !important;
    justify-content: space-between;
    padding: 23px 12px;
    gap: 11px;
    margin-left: -100px;
    border-radius: 10px 0px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 99999;
    top: 40px;

    .platform-items {
      cursor: pointer;
      height: 45px;
      padding: 10px 15px 10px 10px;
      align-items: center;
      gap: 13px;
      border-radius: 12px;
      background: #fff;

      .left {
        gap: 13px;

        h6 {
          color: var(--primary-dark, #4b2e83);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%;
          /* 19.2px */
        }
      }

      .right-cursor {
        transform: translateX(0%);
        transition: transform 0.3s ease-in-out;
      }

      &:hover {
        .right-cursor {
          transform: translateX(50%);
        }
      }
    }
  }
}

.mobile-navbar {
  display: none;
}

.activeClass {
  color: #4C097D !important;

  .icon-rotate {
    color: #4C097D !important;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
  }

  // &:after {
  //   width: 100%;
  //   content: "";
  //   display: block;
  //   height: 3px;
  //   left: 50%;
  //   transform: translate(-50%);
  //   bottom: -18%;
  //   position: absolute;
  //   border-radius: 5px;
  //   background: var(
  //     --3-color-grad,
  //     linear-gradient(90deg, #4b2e83 0%, #4C097D 52%, #4b2e83 100%)
  //   );
  //   opacity: 0.8;
  //   transition: width 0.8s ease 0s, opacity 0.3s ease 0s;
  // }
}

@media only screen and (max-width: 1700px) {
  .platform-hover-container {
    left: 33%;
  }
}

@media only screen and (max-width: 1620px) {
  .navbar-container {
    .navbar-inner-section {
      width: 90%;

      .logo-container {
        img {
          max-width: 90%;
        }
      }

      .menu-container {
        ul {
          li {
            font-size: 16px;
            margin: 10px 20px;
            height: 30px;
          }
        }
      }

      .navbarbtn-container {
        color: white !important;

        button {
          width: 153px;
          padding: 13px;
          gap: 10px;
          font-size: 16px;
          margin-top: 17px;
          margin-left: -7px;
        }
      }
    }
  }

  .ant-space-item,
  .ant-space {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .platform-hover-container {
    left: 30%;
  }

  .navbar-container {
    .navbar-inner-section {
      width: 100%;

      .logo-container {
        img {
          width: 90px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .platform-hover-container {
    left: 27%;
  }

  .navbar-container .navbar-inner-section .navbarbtn-container button {
    // width: 110px;
    padding: 13px;
    gap: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-container .navbar-inner-section .menu-container ul li {
    font-size: 14px;
    margin: 10px;
    height: 30px;
  }

  .navbar-container {
    .navbar-inner-section {
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 1000px) {

  .navbar-container,
  .platform-hover-container {
    display: none;
  }

  .mobile-navbar {
    width: 100%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .mobileLogo {
      width: auto;
      height: 40px;
    }

    .menu-btn {
      background: transparent;
      border: none;
      width: 56px;
      height: 39px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .menu-btn-icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .logostyle {
    width: auto;
    height: 40px;
  }

  .mobile-menu-drawer {
    background: black !important;
    border: 1px solid #4b2e83 !important;
  }

  ul {
    li {
      color: white;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      list-style: none;

      a {
        color: white;
        text-decoration: none;

        &.active {
          font-weight: 700;
          color: white;
        }
      }
    }
  }

  .signupBtn {
    width: 120px;
    height: fit-content;
    padding: 12px;
    border-radius: 72px;
    background: var(--primary-dark, #4b2e83);
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }

  .activeClass {
    font-weight: 700;
    color: #000000;
  }

  .mobil-dropdown {
    border: 1px solid black;
    border-radius: 10px;
    width: fit-content;
    margin-top: 10px;
    width: 170px;
    padding: 2px 0px 2px 2px !important;

    li {
      margin: 10px 5px !important;

      img {
        width: 20px;
        height: 20px;
      }

      a {
        color: #4b2e83;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;

        &.active {
          font-weight: 500;
          color: #4b2e83;
        }
      }
    }
  }

  :where(.css-dev-only-do-not-override-1ae8k9u) a:hover {
    color: #4b2e83;
  }

  .languages-main-outer {
    right: 54%;
    // max-width: 100px;
  }

  // .ant-drawer-content-wrapper {
  //   width: 388px;
  // }
}

.submenu {
  color: white;
  font-weight: normal;
  padding: 3px 0px;
  margin-left: 10px;
  padding-left: 20px;
  // background-color: #4b2e83;
  // opacity: 0.7;



  .menuItem {
    padding-top: 10px;
    z-index: 999;
    opacity: 1;
    cursor: pointer;
  }

  .button2 {
    width: 153px;
    padding: 13px;
    gap: 10px;
    font-size: 16px;
    margin-top: 17px;
    margin-left: -7px;
  }

}
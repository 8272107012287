.hosting-containers-container {
  background-color: #000; // Black background
  color: #fff;
  // padding: 50px 0;
  text-align: center;
  margin-top: 6rem;
  margin-bottom: 4rem;

  .welcome-section {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-family: "Gowun Batang", serif !important;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      line-height: 5px;

    }

    p {
      font-size: 1.2rem;
      color: #ccc;
      line-height: 1.8;
      margin-top: 10px;
    }
  }

  .solutions-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
    flex-wrap: wrap;

    .solution-card {
      background-color: #1c1c1c;
      color: #fff;
      border-radius: 10px;
      padding: 30px;
      width: 300px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-10px); // Animation on hover
      }

      .icon {
        margin-bottom: 15px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 40px;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
        color: #aaa;
      }
    }
  }

  @media (max-width: 768px) {

    .welcome-section {
      h2 {
        font-size: 1.8rem;
        /* Reduce the font size */
        line-height: 1.5;
      }

      p {
        font-size: 1rem;
        /* Smaller font for mobile */
        text-align: justify;
        /* Justified text on mobile */
        padding: 0px 30px;
        line-height: 1.6;
      }
    }

  }

  @media (max-width: 480px) {

    .welcome-section {
      h2 {
        font-size: 1.8rem;
        /* Reduce the font size */
        line-height: 1.5;
      }

      p {
        font-size: 1rem;
        /* Smaller font for mobile */
        //   text-align: justify; /* Justified text on mobile */
        line-height: 1.6;
        text-align: justify;
        /* Justified text on mobile */
        padding: 0px 30px;
      }
    }

  }
}
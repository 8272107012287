@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

.reserve-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background-color: #1c1c1c;

  .content-section {
    flex: 1;
    padding: 20px;
    max-width: 50%;

    h1 {
      font-size: 2rem;
      color: #fff;
      margin-bottom: 20px;
      // font-weight: bold;
      // font-family: "Gowun Batang", serif !important;

      // text-shadow: 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83,
      //   4px 4px 4px #4b2e83;
    }

    p {
      font-size: 1rem;
      color: #fff;
      margin-bottom: 20px;
    }
  }

  .image-section {
    flex: 1;
    max-width: 50%;
    padding: 20px;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .content-section,
    .image-section {
      max-width: 100%;
      padding: 10px;
    }

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.9rem;
      text-align: justify;
    }
  }

  @media (max-width: 576px) {
    padding: 20px;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.8rem;
      text-align: justify;

    }

    .reserve-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
}
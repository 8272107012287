.slider-container {
  max-width: 100%;
  padding: 40px;

  .slick-slide {
    padding: 0 10px;
    display: flex;
    justify-content: center;
  }

  .product-card {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid gray;
    padding: 15px;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
    color: #fff !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
      transform: translateY(-5px);
    }

    .btn {
      background: transparent;
      color: white;
      border: 1px solid white;
      border-radius: 10px;
      padding: 4px 6px;
      font-size: 14px;
      margin-top: 5px;
      width: 100%;
      transition: background-color 0.3s ease;
      /* Add transition for smooth hover effect */

      &:hover {
        background-color: #4C097D;
        font-weight: bold;
        /* Change background color on hover */
      }
    }

    img {
      width: 100%;
      height: 170px;
      object-fit: cover;
      margin-bottom: 10px;
      border-radius: 5px;
    }

    h3 {
      font-size: 0.9rem;
      color: white;
      margin-bottom: 8px;
      line-height: 1.2;
      height: 2.4rem;
      overflow: hidden;
    }

    .product-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1rem;
        color: white;
        margin: 0;

        &.out-of-stock {
          color: #4C097D !important;
        }

        &.power {
          color: #9753c7;
        }
      }
    }
  }

  /* Slick Carousel Customization */
  .slick-prev,
  .slick-next {
    color: #fff;
    background-color: #4C097D;
    border-radius: 50%;
    padding: 5px;
  }

  .slick-dots {
    bottom: -30px;

    li button:before {
      color: #4C097D;
    }

    li.slick-active button:before {
      color: #28a745;
    }
  }

  @media (max-width: 768px) {
    img {
      height: 120px;
    }
  }

  @media (max-width: 576px) {
    img {
      height: 100px;
    }
  }
}
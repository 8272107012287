.footer-container {
  background-color: black;
  padding: 2em 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 5rem;

  .footer-row {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5rem;
    flex-wrap: wrap;

    h3 {
      color: #4C097D !important;
      margin-bottom: 1em;
      font-size: 1.2rem;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 0.5em;

        a {
          color: #b8b8b8;
          text-decoration: none;
          transition: color 0.3s ease;

          &:hover {
            color: #4C097D !important;
          }
        }
      }
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;

      svg {
        color: #4C097D !important;
        margin-right: 10px;

      }

      .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        p {
          margin: 0;
          font-size: 16px;
        }
      }
    }

    .social-icons {
      display: flex;
      justify-content: center;

      a {
        color: #a890d3;
        font-size: 1.5rem;
        margin: 0 10px;
        transition: color 0.3s ease;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding-top: 2em;
    border-top: 1px solid #444;
    color: #b8b8b8;
    font-size: 14px;
  }

  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .footer-row {
      justify-content: center;
      padding-bottom: 2rem;
    }

    .footer-section {
      flex: 1 1 100%;
      margin-bottom: 2em;

      .contact-info {
        align-items: center;
        /* Center items in contact info */
        text-align: center;
        /* Center text for smaller screens */
      }
    }

    .social-icons {
      justify-content: center;
    }
  }

  @media (max-width: 576px) {
    .footer-section {
      text-align: center;
      flex: 1 1 100%;
    }

    .footer-bottom {
      font-size: 12px;
    }

    .social-icons {
      justify-content: center;
    }
  }
}
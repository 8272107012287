@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

.hosting-services-main {
  padding: 20px 20px 30px 20px;
  background: '#4b2e83';


  h1 {
    font-size: 3rem;
    color: #fff;
    // text-shadow: 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83, 4px 4px 4px #4b2e83,
    //   4px 4px 4px #4b2e83;
    margin-bottom: 10px;
    // text-align: start !important;
    font-weight: bold;
    // font-family: "Gowun Batang", serif !important;
  }

  p {
    max-width: 80%;
  }

  .carousel-container {
    width: 90%;
    margin-top: 50px;
  }

  .carousel-container .slick-slide {
    padding: 0 10px;
    /* Adjust the value to create the desired gap */
  }

  .carousel-container img {
    width: 100%;
    height: 300px;
    margin-left: 20px;
    margin-right: 20px;
    // border: 1px solid #4b2e83;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .hosting-services-main {
    h1 {
      font-size: 34px;
      text-align: start !important;
      // font-family: "Gowun Batang", serif !important;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      max-width: 100%;
      text-align: justify !important;
    }
  }
}
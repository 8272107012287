.blog-detail-card {
    background-color: transparent; // Make the background transparent
    color: white; // Set text color to white
    .card-img-top {
        width: 100%; // Set a default width for the image
        max-width: 600px; // Reduce the size of the image, adjust as needed
        height: auto;
        margin: 0 auto; // Center the image
    }
    .card-body {
        padding: 20px;
        text-align: left;
    }
}

// For small screens, justify the content
@media (max-width: 768px) {
    .card-body {
        text-align: justify; // Justify text for small screens
    }
    p.card-text{
        text-align: justify; // J
    }
}


@media screen and (max-width: 480px) {
    .card-body {
        text-align: justify; // Justify text for small screens
    }
    p.card-text{
        text-align: justify; // J
    }
    .card-title{
        line-height: 35px;
         padding: 10px 0px;
    }
}
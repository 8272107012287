body {
  background: #050505;
  color: var(--white) !important;
}

html {
  scroll-behavior: smooth;
}
// ---------------custom margins

//gap classes
.gap-15 {
  gap: 15px;
}
.gap-35 {
  gap: 35px;
}
// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//width and height classes
.w-100 {
  width: 100%;
}

// Flex classes

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

//margin classes

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overlay {
  position: relative;
}
